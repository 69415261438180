import Link from "next/link";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import ContentfulImage from "@/components/ContentfulImage";
import { UserContext } from "@/context/User";
import useAcdl from "@/hooks/useAcdl";
import ImageSizes from "@/utils/helpers/images";
import withScreenDimensions from "@/utils/helpers/screen_dimensions";

import styles from "./LocationCard.module.scss";

const ConditionalLinkWrapper = ({ isDesktop, wrapper, children }) => {
  return isDesktop ? children : wrapper(children);
};

const LocationCard = ({
  thumbnail,
  webName,
  address,
  clubDetailPageURL,
  city,
  facilityId,
  region,
  state,
  status,
  type,
  zip,
  phoneNumber,
  position,
  featuredAmenities,
  isDesktop,
  description,
}) => {
  const { acdlPush } = useAcdl();
  const { getDataLayerInfo } = useContext(UserContext);

  const sendAcdlEvent = () => {
    acdlPush({
      club: {
        clubName: webName.toLowerCase(),
        clubRegion: region.toLowerCase(),
        clubStatus: status.toLowerCase(),
        clubType: type.toLowerCase(),
        facilityID: facilityId,
      },
      event: "tile_tapped",
      tile: {
        category: "club tile",
        location: "other clubs || list",
        position: position.toString(),
        step: "club selected",
        tileName: webName.toLowerCase(),
      },
      user: getDataLayerInfo(),
    });
  };

  const url = `${clubDetailPageURL}`;

  return (
    <div className={`col-lg-5 col-md-16`}>
      <div
        className={`${styles.locationCardContainer} d-lg-block d-md-flex d-sm-flex`}
      >
        <div className={`${styles.imageWrap} d-lg-block d-md-none d-sm-none`}>
          <Link
            aria-label="imageWrap"
            href={url}
            onClick={() => sendAcdlEvent()}
            tabIndex={-1}
          >
            <ContentfulImage
              alt={description}
              sizes={ImageSizes.locationCard}
              src={thumbnail}
              title={webName}
            />
          </Link>
        </div>

        <div className={styles.bodyWrapper}>
          <Link href={url} onClick={() => sendAcdlEvent()}>
            <p className={styles.title}>
              <span>{webName}</span>
            </p>{" "}
          </Link>
          <ConditionalLinkWrapper
            isDesktop={isDesktop}
            wrapper={(children) => (
              <Link href={url} onClick={() => sendAcdlEvent()}>
                {children}
              </Link>
            )}
          >
            <>
              <p className={styles.address}>
                {address}
                <br />
                {`${city}, ${state} ${zip}`}
                <br />
                <span className="d-lg-block d-md-none d-sm-none">
                  {phoneNumber}
                </span>
              </p>

              {featuredAmenities && (
                <div
                  className={`d-lg-block d-md-none d-sm-none ${styles.featuredAmenitiesContainer}`}
                >
                  <p className={styles.amenitiesTitle}>Featured Amenities</p>
                  <ul>
                    {featuredAmenities.slice(0, 2).map((amenity) => (
                      <li key={amenity}>{amenity}</li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          </ConditionalLinkWrapper>
        </div>

        <div className={`${styles.mobileImageWrapper} d-lg-none d-md-block`}>
          <Link href={url} onClick={() => sendAcdlEvent()}>
            <img
              alt="responsiveThumbnail"
              className={styles.responsoveThumbnail}
              src={`https://${thumbnail}`}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

LocationCard.propTypes = {
  address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  clubDetailPageURL: PropTypes.string.isRequired,
  description: PropTypes.string,
  facilityId: PropTypes.string.isRequired,
  featuredAmenities: PropTypes.array,
  isDesktop: PropTypes.bool.isRequired,
  phoneNumber: PropTypes.string.isRequired,
  position: PropTypes.number.isRequired,
  region: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  webName: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
};

export default withScreenDimensions(LocationCard);
